const ALLOWED_DOMAINS = {
  TESTING: ["http://localhost:3006", "http://localhost:3000"],
  DEVELOPMENT: [
    "https://www.pallas-dev.com",
    "https://www.cashflowportal.dev",
  ],
  STAGING: [
    "https://www.pallas-staging.com",
    "https://www.cashflowportal-staging.net/",
  ],
  PRODUCTION: ["https://www.investbase.com", "https://www.cashflowportal.com"],
};

export const getWriteOnlyDomains = () => {
  if (!process.env.REACT_APP_WRITE_ONLY_SSO_DOMAINS) {
    return [];
  }
  return process.env.REACT_APP_WRITE_ONLY_SSO_DOMAINS.split(",");
};

export const getAllowedDomains = () => {
  if (process.env.REACT_APP_ENV == "production") {
    return ALLOWED_DOMAINS.PRODUCTION;
  } else if (process.env.REACT_APP_ENV == "staging") {
    return ALLOWED_DOMAINS.STAGING;
  } else if (process.env.REACT_APP_ENV == "development") {
    return ALLOWED_DOMAINS.DEVELOPMENT;
  }
  return ALLOWED_DOMAINS.TESTING;
};

export const getCashflowMarketplaceUrl = () => {
  if (process.env.REACT_APP_ENV == "production") {
    return ALLOWED_DOMAINS.PRODUCTION[0];
  } else if (process.env.REACT_APP_ENV == "staging") {
    return ALLOWED_DOMAINS.STAGING[0];
  } else if (process.env.REACT_APP_ENV == "development") {
    return ALLOWED_DOMAINS.DEVELOPMENT[0];
  }
  return ALLOWED_DOMAINS.TESTING[0];
};

export const getCashflowPortalUrl = () => {
  if (process.env.REACT_APP_ENV == "production") {
    return ALLOWED_DOMAINS.PRODUCTION[1];
  } else if (process.env.REACT_APP_ENV == "staging") {
    return ALLOWED_DOMAINS.STAGING[1];
  } else if (process.env.REACT_APP_ENV == "development") {
    return ALLOWED_DOMAINS.DEVELOPMENT[1];
  }
  return ALLOWED_DOMAINS.TESTING[1];
};

export const parentDomain = {
  parentOrigin: document.referrer.substring(
    0,
    document.referrer.lastIndexOf("/")
  ),
};
export const encodeOrigin = () => {
  return encodeURIComponent(parentDomain.parentOrigin);
};

export const sendPayload = (payload) => {
  if (getAllowedDomains().includes(parentDomain.parentOrigin)) {
    window.top?.postMessage(payload, parentDomain.parentOrigin);
  }
};
